import React from "react";
import { Helmet } from "react-helmet-async";
import icon from "../Images/ORn.png";
function DocHead(props) {
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.descripcion} />
        {props.icon ? (
          <link rel="icon" href={props.icon} type="image/png" />
        ) : (
          <link rel="icon" href={icon} type="image/png" />
        )}
        <script async src="https://www.google-analytics.com/analytics.js" />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-CR4H3FV3Q7"
        ></script>
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

         gtag('config', 'G-CR4H3FV3Q7');`}
        </script>
      </Helmet>
    </div>
  );
}
export default DocHead;
