//firebase deploy \--only hosting:beta
import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import Public from "./Routes/public";
import Banner from "./Components/Banner";
import ReproductorProvider from "./Context/ReproductorProvider";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ReproductorProvider>
          <HelmetProvider>
            <BrowserRouter>
              <div className="App-banner">
                <Banner />
              </div>
              <Public />
            </BrowserRouter>
          </HelmetProvider>
        </ReproductorProvider>
      </header>
    </div>
  );
}
export default App;
