import React from "react";
import ImagenRadio from "./ImagenRadio";
import { Link } from "react-router-dom";

// ArgBsAsAvellanedaFMPasion
function CardRadioFavoritas(props) {
  const dt = props.datos;
  const img = dt.img;

  const freq = dt.freq;

  const handleClick = () => {
    if (window.scrollY)
      sessionStorage.setItem(window.location.pathname, window.scrollY);
  };

  return (
    <div className="App-CardRadio-Favoritas">
      <Link
        preventScrollReset={true}
        to={"/" + freq + "/"}
        style={{
          textDecoration: "none",
          color: "white",
          cursor: "pointer",
        }}
        onClick={handleClick}
        key={freq}
      >
        <div>
          <div
            style={{
              height: "66px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              textAlign: "center",
            }}
          >
            <ImagenRadio imagen={img} ancho="66px" key={img} />
          </div>
        </div>
      </Link>
    </div>
  );
}
export default CardRadioFavoritas;
