import React from "react";
import { useContext } from "react";
import Logo from "../Images/OrBanner.png";
import ReproductorContext from "../Context/ReproductorContext";
import Reproductor from "./Reproductor";
import PlayingAnimation from "./PlayingAnimation";
import ImagenReproductor from "./ImagenReproductor.js";

function Banner() {
  const context = useContext(ReproductorContext);
  
  const handleChangeVolume = (event) => {
    context.handleVolumen(event.target.value / 100)
  }
  const PlayPause = () => {

    context.HandlePlay(!context.play);
  };
  return (
    <div>
      <ReproductorContext.Consumer>
        {(context) => (
          <div style={{ margin: "2px" }}>
            <div>
              <img
                alt="OnlineRadios App - Escuchá radios online, en vivo y gratis las 24hs"
                src={Logo}
                style={{ height: "30px", verticalAlign: "top", marginBottom: "10px" }}
              ></img>
            </div>


            <div
              className="App-PlayButton"
              onClick={PlayPause}
            
            >
               <div >
                <PlayingAnimation />
              </div>

              <div style={{ marginLeft: "20px", cursor: "pointer" }}>
                <ImagenReproductor imagen={context.img} ancho="30px" nombre={""} />
              </div>



              <label style={{ marginLeft: "20px", marginRight: "20px", cursor: "pointer" }}> {context.nombre}</label>


              <Reproductor />
              <label
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                  color:"#FF914D"

                }}
              >
                {context.texto}
              </label>
             

            </div>
           
              <div>


                <input className="App-Volumen" type={"range"} min={0} max={100} defaultValue={100}  onChange={handleChangeVolume} style={{ width: "80%" ,margin:"20px"}} />

              </div>
           
          </div>

        )}
      </ReproductorContext.Consumer>
    </div>
  );
}
export default Banner;
