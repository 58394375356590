import React from "react";
function ImagenRadio(props){
    const imagen = props.imagen
    return(
        <div>
            <img src={imagen}
            width={props.ancho}
            alt={props.nombre}
            loading="eager"/>
        </div>
    )
}
export default ImagenRadio