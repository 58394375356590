import React from "react";
function ImagenReproductor(props){
    const imagen = props.imagen
    return(
        <div>
            <img src={imagen}
            width={props.ancho}
            loading="eager"
            alt=""/>
        </div>
    )
}
export default ImagenReproductor