import React from "react";
import Estilo from "../Components/Loading.css";
function Loading(props) {




  return (
    <div>
     <div className="lds-ripple" style={Estilo}><div></div><div></div></div>
     

    </div>
  )
}
export default Loading;
