import React from "react";
import EstiloPlay from "./PlayingAnimation.css"
import ReproductorContext from "../Context/ReproductorContext";

function PlayingAnimation(props) {
  return (
    <div>
      <ReproductorContext.Consumer>
        {
          (context) =>
          (
            (context.play && context.listo) && 
              <div style={EstiloPlay}>
                <div className="playing">
                  <span className="playing__bar playing__bar1"></span>
                  <span className="playing__bar playing__bar2"></span>
                  <span className="playing__bar playing__bar3"></span>
              
                </div>
              </div>
          )
           
        }
      </ReproductorContext.Consumer>
    </div>


  );
}
export default PlayingAnimation;
