import React from "react";
import DocHead from "../Components/DocHead";
import GoBackButton from "../Components/GoBackButton"
function Home() {
  return (
    <div>
      <DocHead title="OnlineRadios App"></DocHead>
      <div>
        <br />
        <br />
        <label>404</label>

        <label>Radio no encontrada</label>
        <br />
        <GoBackButton />
        <br />
        <br />
      </div>
    </div>
  );
}
export default Home;
