import { useState, useEffect } from "react";
import React from "react";
import firebase from "../Config/firebase";
import { collection, getDocs } from "firebase/firestore";

//import { signInWithEmailAndPassword } from "firebase/auth";
import { useMediaQuery } from "react-responsive";

import CardRadioFavoritas from "./CardRadioFavoritas";

function RadiosFavoritas() {
  const [radios, setradios] = useState([]);
  const [reload, setReload] = useState(true);
  const [mostrarfavoritas, setMostrarfavoritas] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 800px)",
  });

  useEffect(() => {
    if (reload) {
      request();
    }
  });
  async function request() {
    try {
      const querySnapshot = await getDocs(collection(firebase.db, "radios"));
      if (querySnapshot.docs) {
        setradios(querySnapshot.docs);
        setReload(false);
        const local = JSON.stringify(localStorage);
        setMostrarfavoritas(local.includes("--fav--"));
      }
    } catch (e) {
      console.log("Error home", e);
    }
  }
  if (mostrarfavoritas) {
    return (
      <div className="App-home-favoritas">
        <label style={{ marginTop: "30px", marginBottom: "0px" }}>
           
        </label>
        <div >
        {isDesktopOrLaptop ? (
          <div >
            {radios.map(
              (radio) =>
                localStorage.getItem(radio.data().nombre) && (
                  <CardRadioFavoritas datos={radio.data()} key={radio.data().id} />
                )
            )}
          </div>
        ) : (
          <div>
            {radios.map(
              (radio) =>
                localStorage.getItem(radio.data().nombre) && (
                  <CardRadioFavoritas datos={radio.data()} key={radio.data().id} />
                )
            )}
          </div>
        )}
        </div>
      </div>
    );
  }
}

export default RadiosFavoritas;
