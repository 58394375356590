import React from "react";
import { Routes, Route } from "react-router-dom";
import Listen from "../Pages/Listen";
import Home from "../Pages/Home";
import Gracias from "../Pages/Gracias"

function Public() {

  

  return (
     
      <Routes>
       
        <Route path="/" element={<Home />} exact>
         <Route index element={<Home/>} />
        </Route>
        <Route path=":freq/" element={<Listen />}></Route>
        <Route path="/gracias/" element={<Gracias/>}></Route>
       {/*<Route path="*" element={<NotFound />} />*/}
       
      </Routes>
  
  );
}
export default Public;
