import React from "react";
import { faHeart as Activo  } from "@fortawesome/free-solid-svg-icons";
import  { faHeart as Inactivo}  from "@fortawesome/free-regular-svg-icons" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
function Favorito(props) {
  const esFavorito = props.fav;
  const EstiloActivo ={
     "color":"red",
     "cursor":"pointer"
  }
  const EstiloInactivo ={
    "color":"gray",
    "cursor":"pointer"
  }


 
  return (
    <div>
      {esFavorito ? (
       
        <Button variant="outline-primary" size="lg" style={{color:"red"}}> <FontAwesomeIcon icon={Activo} style={EstiloActivo}/></Button> )
      
    
        :
        (
          
          <Button variant="outline-primary" size="lg"> <FontAwesomeIcon icon={Inactivo} style={EstiloInactivo}/></Button> 
        )}
      
    </div>
  );
}
export default Favorito;
